import getMobileOperatingSystem from "design-system/src/Navigation/Footer/FooterWidget/FooterAppLinks/FooterAppLinks.utils";
import isSafari from "design-system/src/Utils/isSafari";
import {
  GATSBY_CONF_SHOP_NAME,
  GATSBY_ENV_COUNTRY_ISO3166,
} from "@src/utils/constants";
import { GATSBY_CURRENCY_CODE } from "@src/backbone/prototypes/formatPrice";
import { useEffect } from "react";
import ApiRequest from "@src/backbone/prototypes/ApiRequest";

// web: safari only; mobile: ios only
export const APPLE_PAY_MERCHANT_ID =
  process.env.GATSBY_CONF_APPLE_PAY_MERCHANT_ID || "merchant.sushishop.fr";

export const APPLE_PAY_STORE_NAME =
  process.env.GATSBY_CONF_APPLE_PAY_STORE_NAME || "SUSHISHOP_FR";
export const checkApplePayDevice = () => {
  return true;
};

export enum ApplePayPaymentCredentialStatus {
  /**
   * Confirms that the device supports Apple Pay and there’s at least one active payment credential in Wallet that qualifies for payments on the web. Show an Apple Pay button and offer Apple Pay as the primary, but not necessarily sole, payment option.
   */
  CREDENTIALS_AVAILABLE = "paymentCredentialsAvailable",
  /**
   * Confirms that the device supports Apple Pay, but the Wallet information is unknown. Show an Apple Pay button and offer Apple Pay as a possible payment option.
   */
  CREDENTIALS_UNKNOWN = "paymentCredentialStatusUnknown",
  /**
   * Confirms that the device supports Apple Pay and that the device doesn’t have any active payment credentials in Wallet. Offer Apple Pay as a payment option, but don’t make it the sole or default payment option. This gives people the option to set up Apple Pay as part of their purchase.
   */
  CREDENTIALS_UNAVAILABLE = "paymentCredentialsUnavailable",
  /**
   * Indicates that the device doesn’t support Apple Pay. Don’t display an Apple Pay button or offer Apple Pay as a payment option.
   */
  APPLE_PAY_UNSUPPORTED = "applePayUnsupported",
}

export const isApplePaySdkReady = () => {
  return (
    typeof window !== "undefined" &&
    // @ts-ignore
    !!window.ApplePaySession &&
    // @ts-ignore
    !!window.ApplePaySession.applePayCapabilities
  );
};

const applePaySdkScriptId = "apple-pay-sdk";

export const LoadApplePaySdk = async () => {
  return new Promise<ApplePayJS.PaymentCredentialStatus>((resolve, reject) => {
    const ApplePaySdkJsUrl =
      "https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js";
    let script: HTMLScriptElement = document.getElementById(
      applePaySdkScriptId
    ) as HTMLScriptElement;
    const onSdkReady = () => {
        ApplePaySession.applePayCapabilities(APPLE_PAY_MERCHANT_ID)
          .then((capabilities) => {
            resolve(capabilities.paymentCredentialStatus);
          })
          .catch((e) => {
            console.error(e);
            reject();
          });
      },
      fail = () => {
        reject();
      };
    if (isApplePaySdkReady()) {
      onSdkReady();
    } else if (!script) {
      script = document.createElement("script") as HTMLScriptElement;
      script.id = applePaySdkScriptId;
      script.src = ApplePaySdkJsUrl;
      script.async = true;
      script.onload = onSdkReady;
      script.onerror = fail;
      script.crossOrigin = "";
      document.body.appendChild(script);
    } else {
      script.onload = onSdkReady;
      script.onerror = fail;
    }
  });
};

type ApplePayPaymentRequestProps = {
  amount: number;
};

export type ApplePayDoPaymentPayload = {
  encryptedData: string;
  ephemeralKey: string;
  publicKeyHash: string;
  amount: number;
};
export const StartApplePayPayment = async ({
  amount,
}: ApplePayPaymentRequestProps) => {
  return new Promise<{
    session: ApplePaySession;
    payload: ApplePayDoPaymentPayload;
  }>((resolve, reject) => {
    // @ts-ignore
    if (!!ApplePaySession && ApplePaySession.applePayCapabilities) {
      const request: any = {
        countryCode: GATSBY_ENV_COUNTRY_ISO3166,
        currencyCode: GATSBY_CURRENCY_CODE,
        // todo : supportedNetworks by Store ?
        supportedNetworks: ["visa", "masterCard", "amex"],
        merchantCapabilities: ["supports3DS"],
        // todo, support Edenred partial payment
        total: { label: GATSBY_CONF_SHOP_NAME, amount },
      };
      const session = new ApplePaySession(6, request);
      session.oncancel = function (e) {
        console.warn("Apple Pay payment cancelled", e);
        reject();
      };
      session.onvalidatemerchant = function (e) {
        ApiRequest(
          "ToolsPaymentMethod",
          {
            validationUrl: e.validationURL,
          },
          {
            method: "applePaySession",
          }
        )
          .then((data) => {
            session.completeMerchantValidation(data);
          })
          .catch((e: any) => {
            reject(e);
          });
      };
      session.onpaymentauthorized = function (e) {
        const paymentData = e.payment.token.paymentData;
        const {
          data: encryptedData,
          header: { publicKeyHash, ephemeralPublicKey: ephemeralKey },
        } = paymentData;
        const payload: ApplePayDoPaymentPayload = {
          encryptedData,
          ephemeralKey,
          publicKeyHash,
          amount,
        };
        resolve({
          session,
          payload,
        });
      };
      session.begin();
    } else {
      reject();
    }
  });
};
