import { app } from "@src/backbone/model/AppModel";
import LocalStorageModel from "../prototypes/LocalStorageModel";
import { ApplePayPaymentCredentialStatus } from "@src/components/page-content/Checkout/Payment.utils";

//
// Model PaymentBrand
//

class PaymentBrandModel extends LocalStorageModel<{
  name: string;
  short_name?: string;
  directlink: boolean;
  apple_pay?: boolean;
  paymentCredentialStatus?: ApplePayJS.PaymentCredentialStatus;
  brand: string;
  pictures: { id_object_picture: string }[];
}> {
  name = "PaymentBrandModel";
  route = "apiPaymentBrandModel";
  // TODO attention, l'idAttribute n'est pas `id_payment_brand` (car pas migré)
  idAttribute = "id_payment_brand";
  defaults() {
    return { name: "", pictures: [], directlink: false };
  }
  initialize(attributes?: any, options?: any): void {
    // ObjectModel constructor heritage
    super.initialize.apply(this, [attributes, options]);
  }
  isDirectLink() {
    return !!this.get("directlink");
  }

  getName() {
    return this.get("name") || "";
  }
  getShortName() {
    return this.get("short_name") || "";
  }
  getBrand() {
    return this.get("brand") || "";
  }
  getIdPicture(): string {
    const model = this,
      pictures = model.get("pictures");
    return (
      (pictures && pictures[0] && (pictures[0].id_object_picture as string)) ||
      ""
    );
  }

  getPaymentCredentialStatus(): ApplePayJS.PaymentCredentialStatus {
    return (
      this.get("paymentCredentialStatus") ||
      ApplePayPaymentCredentialStatus.CREDENTIALS_UNKNOWN
    );
  }

  isSupported(): boolean {
    if (this.isApplePay()) {
      return (
        this.getPaymentCredentialStatus() !==
        ApplePayPaymentCredentialStatus.APPLE_PAY_UNSUPPORTED
      );
    }
    return true;
  }

  isApplePay(): boolean {
    return !!this.get("apple_pay");
  }

  hasApplePayCredentials(): boolean {
    return (
      this.isApplePay() &&
      this.getPaymentCredentialStatus() ===
        ApplePayPaymentCredentialStatus.CREDENTIALS_AVAILABLE
    );
  }

  getCvcLength(): number {
    const model = this;
    const brand = model.get("brand") || "";
    const cvcLength = /^AMEX$/i.test(brand) ? 4 : 3;
    return cvcLength;
  }
}

PaymentBrandModel.prototype.name = "PaymentBrandModel";
PaymentBrandModel.prototype.route = "apiPaymentBrandModel";
// TODO attention, l'idAttribute n'est pas `id_payment_brand` (car pas migré)
PaymentBrandModel.prototype.idAttribute = "id_payment_brand";

export default PaymentBrandModel;
