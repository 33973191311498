import S from "string";

enum CURRENCY_POSITIONS {
  LEFT = "left",
  RIGHT = "right",
  NONE = "none",
}

enum NEGATIVE_POSITIONS {
  BEFORE_CURRENCY = "before-currency",
  BEFORE_SIGN = "before-sign",
}

const GATSBY_CURRENCY_SIGN = process.env.GATSBY_CURRENCY_SIGN || "€";
const GATSBY_CURRENCY_CODE = process.env.GATSBY_CURRENCY_CODE || "EUR";
const GATSBY_CURRENCY_POSITION =
  process.env.GATSBY_CURRENCY_POSITION || CURRENCY_POSITIONS.RIGHT;
const GATSBY_CURRENCY_DECIMALS_SEPARATOR =
  process.env.GATSBY_CURRENCY_DECIMALS_SEPARATOR || ",";
const GATSBY_CURRENCY_SPACE = process.env.GATSBY_CURRENCY_SPACE || " "; // narrow no break space
const GATSBY_NEGATIVE_AMOUNT_CURRENCY_POSITION = (process.env
  .GATSBY_NEGATIVE_AMOUNT_CURRENCY_POSITION ||
  NEGATIVE_POSITIONS.BEFORE_CURRENCY) as NEGATIVE_POSITIONS; // narrow no break space
const GATSBY_PRICE_DISPLAY_PRECISION = Number(
  process.env.GATSBY_PRICE_DISPLAY_PRECISION || 2
);

export {
  CURRENCY_POSITIONS,
  NEGATIVE_POSITIONS,
  GATSBY_CURRENCY_SIGN,
  GATSBY_CURRENCY_POSITION,
  GATSBY_CURRENCY_CODE,
  GATSBY_CURRENCY_DECIMALS_SEPARATOR,
  GATSBY_CURRENCY_SPACE,
  GATSBY_NEGATIVE_AMOUNT_CURRENCY_POSITION,
  GATSBY_PRICE_DISPLAY_PRECISION,
};

const defaultFormat = {};

export type FormatPriceFormatOptions = {
  auto_round_decimal?: any;
  wrap_sup?: any;
  ignore_space?: any;
};

function formatPrice(
  value: string | number | undefined | null,
  format: FormatPriceFormatOptions = {}
) {
  if (value === undefined || value === null) {
    return "";
  }
  const v = Number(value); // type cast
  const sign = GATSBY_CURRENCY_SIGN;
  let space = GATSBY_CURRENCY_SPACE; // no break space
  // position du symbole monétaire
  // left (default) : gauche
  // right : droite
  // none : masque
  const signPosition = GATSBY_CURRENCY_POSITION, //
    negativePosition = GATSBY_NEGATIVE_AMOUNT_CURRENCY_POSITION;

  let decimals = GATSBY_PRICE_DISPLAY_PRECISION; // 2
  let decimalsFactor = Math.pow(10, GATSBY_PRICE_DISPLAY_PRECISION);
  let decimalsSeparator = GATSBY_CURRENCY_DECIMALS_SEPARATOR; // séparateur des décimales (virgule)

  format = { ...defaultFormat, ...format };

  const { auto_round_decimal, wrap_sup, ignore_space } = format;

  if (auto_round_decimal && v % 1 === 0) {
    decimalsFactor = 1;
    decimals = 0;
  }

  const wrapped_sign = wrap_sup ? `<sup>${sign}</sup>` : sign;

  if (ignore_space) {
    space = "";
  }

  const floatValue = Math.round(v * decimalsFactor) / decimalsFactor;

  return isNaN(floatValue)
    ? ""
    : _formatPriceWithOptions({
        floatValue,
        decimals,
        decimalsSeparator,
        wrapped_sign,
        space,
        negativePosition,
        signPosition,
      });
}

export const useFormatPrice = (
  value?: string | number | null,
  format?: FormatPriceFormatOptions
) => {
  if (value === undefined || value === null) {
    return "";
  }
  const v = parseFloat(value.toString());

  let sign = GATSBY_CURRENCY_SIGN;
  let decimals = GATSBY_PRICE_DISPLAY_PRECISION;

  let space = GATSBY_CURRENCY_SPACE; // no break space
  // position du symbole monétaire
  // left (default) : gauche
  // right : droite
  // none : masque
  const signPosition = GATSBY_CURRENCY_POSITION, //
    negativePosition = GATSBY_NEGATIVE_AMOUNT_CURRENCY_POSITION;

  let decimalsFactor = Math.pow(10, decimals);
  let decimalsSeparator = GATSBY_CURRENCY_DECIMALS_SEPARATOR; // séparateur des décimales (virgule)

  format = { ...defaultFormat, ...format };
  const { auto_round_decimal, wrap_sup, ignore_space } = format;
  if (auto_round_decimal && v % 1 === 0) {
    decimalsFactor = 1;
    decimals = 0;
  }

  const wrapped_sign = wrap_sup ? `<sup>${sign}</sup>` : sign;

  if (ignore_space) {
    space = "";
  }

  const floatValue = Math.round(v * decimalsFactor) / decimalsFactor;
  return isNaN(floatValue)
    ? ""
    : _formatPriceWithOptions({
        floatValue,
        decimals,
        decimalsSeparator,
        wrapped_sign,
        space,
        negativePosition,
        signPosition,
      });
};

export type formatPriceOptions = {
  floatValue: number;
  decimals: number;
  decimalsSeparator: string;
  wrapped_sign: string;
  space: string;
  negativePosition: string;
  signPosition: string;
};

export const _formatPriceWithOptions = ({
  floatValue,
  decimals,
  decimalsSeparator,
  wrapped_sign,
  space,
  negativePosition,
  signPosition,
}: formatPriceOptions): string => {
  return floatValue
    .toString()
    .replace(/^(-)?([0-9]+).?([0-9]*)$/g, function (match, minus, right, left) {
      let n =
          right +
          (decimals === 0
            ? ""
            : decimalsSeparator + S(left).padRight(decimals, "0")),
        s = wrapped_sign,
        __ = space;
      if (minus) {
        switch (negativePosition) {
          case NEGATIVE_POSITIONS.BEFORE_SIGN:
            s = minus + s;
            break;
          case NEGATIVE_POSITIONS.BEFORE_CURRENCY:
          default:
            n = minus + n;
        }
      }
      switch (signPosition) {
        case CURRENCY_POSITIONS.LEFT:
        default:
          n = s + __ + n;
          break;
        case CURRENCY_POSITIONS.RIGHT:
          n = n + __ + s;
          break;
        case CURRENCY_POSITIONS.NONE:
          break;
      }
      return n;
    });
};

export default formatPrice;
